import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleService } from '../vehicle-service/vehicle.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {
  search;
  status;
  data = [];
  filteredList = [];

  constructor(
    private router: Router,
    private driverService: VehicleService
  ) { }

  ngOnInit() {
    this.driverService.getAllVehicles().subscribe((data) => {
      console.log('Vehicle List ', data);
      if(data) {
        this.data = data;
        this.filteredList = data;
      }
    });
  }

  viewVehicleDetails(id) {
    this.router.navigate(['/vehicles/view',id])
  }

  editVehicleDetails(id) {
    this.router.navigate(['/vehicles/edit',id])
  }

  fillingStatus(vehicle) {
    let status = true;
    for (const key in vehicle) {
      // Skip the keys which do not start with 'is'
      // Skip the key 'isApproved'
      if (!key.startsWith('is') || key === 'isApproved') continue;

      // Decision based on false value of keys which starts with 'is'
      if (!vehicle[key]) {
        status = false;
        break;
      }
    }

    return status;
  }

  onStatusChange(status) {
    console.log(status);
    if (status) {
      this.data = this.filteredList.filter(driver => driver.status === status);
    }
    else {
      this.data = this.filteredList.filter(driver => driver.status === driver.status);
    }
  }

}
