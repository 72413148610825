import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../services/shared-data/shared-data.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  showMenu: boolean = false;

  constructor(private sharedData: SharedDataService, private router: Router) { }
@Input() user;
  ngOnInit() {
    this.sharedData.getMenuState().subscribe((data) => {
  
      this.showMenu = data;
    });
  }

  logout() {
    this.sharedData.updateUser(null);
    localStorage.removeItem("token");
    this.router.navigate(['/notfound']);
  }

  closeMenu() {
    this.sharedData.toggleMenu();
  }

}
