import { Component, OnInit } from '@angular/core';
import { AppService } from './services/app-service/app.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SharedDataService } from './services/shared-data/shared-data.service';
import { AuthService } from './services/auth-service/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  auth_token = '';
  showSideMenu = false;
  userData: any;

  constructor(private router: Router, 
    private sharedData: SharedDataService, 
    private auth: AuthService, 
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    console.log('ngOnInit');
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.auth_token) {
        this.auth_token = params.auth_token;
        this.authentication();
      }
    });
    
    this.sharedData.getUser().subscribe((data) => {
      this.userData = data
      if (data) {
        this.showSideMenu = true;
      }
      else {
        this.showSideMenu = false;
      }
    });

  }

  authentication() {
    console.log('======= APP COMPONENT AUTH ========'); 
    this.auth.me(this.auth_token).subscribe((data: any) => {
      if (data && data.user) {
        this.sharedData.updateUser(data.user);
        localStorage.setItem("token", this.auth_token);
        this.router.navigate(['/dashboard']);
      } else {
        this.sharedData.updateUser(null);
        localStorage.removeItem("token");
        this.router.navigate(['/notfound']);
      }
    });
  }

}


