import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {CamelCasePipe} from './pipes/camel-case-pipe/camel-case.pipe';
import {GooglePlaceDirective} from './directives/google-place-directive/google-place.directive';
import {AgmCoreModule} from '@agm/core';
import {OwlDateTimeModule, OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime';
import {OwlMomentDateTimeModule, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS} from 'ng-pick-datetime-moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {HeaderComponent} from './components/header/header.component';
import {
  NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  SPINNER, POSITION, PB_DIRECTION
} from 'ngx-ui-loader';
import {AppService} from './services/app-service/app.service';
import { AuthorizationGuard } from './services/app-service/auth-guard/authorization-guard.service';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CompanyInformationComponent } from './components/company-information/company-information.component';
import { AddDriverComponent } from './components/driver/add-driver/add-driver.component';
import { DriversComponent } from './components/driver/drivers/drivers.component';
import { VehiclesComponent } from './components/vehicle/vehicles/vehicles.component';
import { AddVehicleComponent } from './components/vehicle/add-vehicle/add-vehicle.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { DriverViewComponent } from './components/driver/driver-view/driver-view.component';
import { VehicleViewComponent } from './components/vehicle/vehicle-view/vehicle-view.component';
import { CompanyViewComponent } from './components/company-information/company-view/company-view.component';
import { LoginComponent } from './components/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptorService } from './services/auth-interceptor/auth-interceptor.service';
import { TimePipe } from './pipes/time-pipe/time.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MenuTogglerComponent } from './components/menu-toggler/menu-toggler.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#00acc1',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 7,
  fgsColor: '#727cf5',//'#727cf5',//#00acc1',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'fading-circle',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  overlayColor: 'rgba(255,255,255,0)',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: 'Loading...',
  textColor: '#000000',
  textPosition: 'center-center'
};


export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CamelCasePipe,
    GooglePlaceDirective,
    NotFoundComponent,
    DashboardComponent,
    CompanyInformationComponent,
    AddDriverComponent,
    DriversComponent,
    VehiclesComponent,
    AddVehicleComponent,
    SideMenuComponent,
    DriverViewComponent,
    VehicleViewComponent,
    CompanyViewComponent,
    LoginComponent,
    TimePipe,
    MenuTogglerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), // import NgxUiLoaderModule
    NgxUiLoaderHttpModule.forRoot({
      exclude: [
        'http://23.253.156.114:7001/api/users/me'],
      showForeground: true
    }),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNoxWXagcapR0tH-bxnJFPK28y_Oro2O0',
      libraries: ['places']
    }),
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    ToastrModule.forRoot(),
    Ng2SearchPipeModule
  ],
  providers: [

    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    {provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    { provide: AuthorizationGuard, useClass: AuthorizationGuard }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
