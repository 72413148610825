import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import * as moment from "moment-timezone";

@Pipe({
  name: "time"
})
export class TimePipe extends DatePipe implements PipeTransform {
  transform(
    value: string | Date,
    format: string = "MM/dd/yyyy h:mm a",
    timezone: string = "America/New_York"
  ): string {
    // console.log(timezone);
    const timezoneOffset = moment(value)
      .tz(timezone)
      .format("Z");
    return super.transform(value, format, timezoneOffset);
  }
}
