import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VehicleService } from '../vehicle-service/vehicle.service';
import { getFileNameFromUrl } from 'src/app/utils/utils.common';
@Component({
  selector: 'app-vehicle-view',
  templateUrl: './vehicle-view.component.html',
  styleUrls: ['./vehicle-view.component.css']
})
export class VehicleViewComponent implements OnInit {
  vehicleId;
  data;

  constructor(
    private vehicleService: VehicleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.url.length === 3) {
      this.vehicleId = this.activatedRoute.snapshot.url[2].path;
      this.getVehicleDetails();
    }

  }
  getVehicleDetails() {
    this.vehicleService.getVehicleDetails(this.vehicleId).subscribe((data) => {
      console.log(data);
      this.data = data;
    });
  }
  getFileName(url){
    return getFileNameFromUrl(url);
  }
}
