import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from '../driver-service/driver.service';
import { getFileNameFromUrl } from 'src/app/utils/utils.common';
@Component({
  selector: 'app-driver-view',
  templateUrl: './driver-view.component.html',
  styleUrls: ['./driver-view.component.css']
})
export class DriverViewComponent implements OnInit {
  data;
  driverId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private driverService: DriverService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.url.length === 3) {
      this.driverId = this.activatedRoute.snapshot.url[2].path;
      this.getDriverDetails();
    }
  }

  getDriverDetails() {
    this.driverService.getDriverDetails(this.driverId).subscribe((data) => {
      console.log(data);
      this.data = data;
    });
  }
  getFileName(url){
    return getFileNameFromUrl(url);
  }
}

