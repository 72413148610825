import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedDataService } from '../shared-data/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  SERVER_URL = environment.URL;

  constructor(
    private http: HttpClient,
    private router: Router,
    private sharedData: SharedDataService
  ) { }

  me(auth_token) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + auth_token
    });
    let options = { headers: authHeader };
    return this.http.get(this.SERVER_URL + "users/me", options);
  }

  getToken(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

}
