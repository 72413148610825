import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationGuard } from './services/app-service/auth-guard/authorization-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CompanyInformationComponent } from './components/company-information/company-information.component';
import { DriversComponent } from './components/driver/drivers/drivers.component';
import { AddDriverComponent } from './components/driver/add-driver/add-driver.component';
import { VehiclesComponent } from './components/vehicle/vehicles/vehicles.component';
import { AddVehicleComponent } from './components/vehicle/add-vehicle/add-vehicle.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DriverViewComponent } from './components/driver/driver-view/driver-view.component';
import { VehicleViewComponent } from './components/vehicle/vehicle-view/vehicle-view.component';
import { CompanyViewComponent } from './components/company-information/company-view/company-view.component';
import { LoginComponent } from './components/login/login.component';

// canActivate: [AuthorizationGuard]
const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizationGuard] },
  { path: 'dashboard/company-view', component: CompanyViewComponent, canActivate: [AuthorizationGuard] },
  { path: 'company-information', component: CompanyInformationComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers', component: DriversComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers/add', component: AddDriverComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers/edit/:id', component: AddDriverComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers/view/:id', component: DriverViewComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicles', component: VehiclesComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicles/add', component: AddVehicleComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicles/edit/:id', component: AddVehicleComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicles/view/:id', component: VehicleViewComponent, canActivate: [AuthorizationGuard] },

  { path: '**', redirectTo: '/notfound' },
  { path: 'notfound', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
