import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private http: HttpClient) { }


  saveVehicle(data, vehcileId) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (data[key] instanceof Object) {
        formData.append(key, data[key].file);
      } else {
        formData.append(key, data[key]);
      }
    });

    if(vehcileId) {
      return this.http.put<any>(environment.URL + 'fleetalivis/' + vehcileId, formData)
    } else {
      return this.http.post<any>(environment.URL + 'fleetalivis' , formData);
    }
  }
  
  deleteDocument(vehicleId,formKey) : Observable<any> {
    const formData: FormData = new FormData();
    formData.append(formKey, '');
    return this.http.put<any>(environment.URL + 'fleetalivis/' + vehicleId, formData);
  }

  getAllVehicles(): Observable<any> {
    return this.http.get<any>(environment.URL + 'fleetalivis')
  }

  getVehicleDetails(id): Observable<any> {
    return this.http.get<any>(environment.URL + 'fleetalivis/'+id)
  }


}
