import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private http: HttpClient) { }

  saveDriver( data, driverId): Observable<any> {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (data[key] instanceof Object) {
        formData.append(key, data[key].file);
      } else {
        formData.append(key, data[key]);
      }
    });
    if(driverId) {
      return this.http.put<any>(environment.URL + 'driveralivis/' + driverId, formData)
    } else {
      return this.http.post<any>(environment.URL + 'driveralivis' , formData);
    }
  }

  // driverExist(data, driverId): Observable<any> {
  //   const formData = new FormData();
  //   Object.keys(data).forEach(key => {
  //     if (data[key] instanceof Object) {
  //       formData.append(key, data[key].file);
  //     } else {
  //       formData.append(key, data[key]);
  //     }
  //   });

  //   return this.http.get<any>(environment.URL + 'driverexist');
  //   // if(driverId) {
  //   //   return this.http.put<any>(environment.URL + 'driveralivis/' + driverId, formData)
  //   // } else {
  //   //   return this.http.post<any>(environment.URL + 'driveralivis' , formData);
  //   // }
  // }

  deleteDocument(driverId,formKey) : Observable<any> {
    const formData: FormData = new FormData();
    formData.append(formKey, '');
    return this.http.put<any>(environment.URL + 'driveralivis/' + driverId, formData);
  }

  getAllDrivers(): Observable<any> {
    return this.http.get<any>(environment.URL + 'driveralivis')
  }

  getDriverDetails(id): Observable<any> {
    return this.http.get<any>(environment.URL + 'driveralivis/'+id)
  }


  verifyDriverEmail(driverEmail): Observable<any> {
    return this.http.get<any>(environment.URL + 'drivers/isemail/' + driverEmail);
  }
}
