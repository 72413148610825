import { FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { imageProcessing, ImageSnippet } from './image-processing-utils';

export function getFileNameFromUrl(url) {
    if (url && url !== '') {
        const urlSegments = url.split('/');
        if (urlSegments)
            return decodeURI(urlSegments[urlSegments.length - 1]);
        else
            return '';
    } else {
        return '';
    }
}

export function getAddress(place, form, address, latitude, longitude) {
    console.log(place);
    form[address].setValue(place.formatted_address);
    form[latitude].setValue(place.geometry.location.lat());
    form[longitude].setValue(place.geometry.location.lng());
}

export function logFormValues(form: FormGroup, num) {
    if (form.valid) {
        console.log(`FORM${num} VALID`, form.value);
    }
    else {
        console.log(`FORM${num} INVALID`, form.value);
    }
}

export function fileProcessing(ImageInput, form, imageFile) {
    imageProcessing(ImageInput).subscribe((result) => {
        // form[imagePath].setValue(result.src);
        // form[imageFile].setValue(result.file);
        form[imageFile].setValue(result);
    });
}

export function onDateChange(event, control) {
    console.log(moment(event).toISOString());
    if (event) {
        control.setValue(moment(event).toISOString());
    }
}

export function formValidations(formField, name?) {

    // console.log('FormValidations', formField, name)
    if (formField.value === null) {
        return `<span class="text-red-500"> Required </span>`;
    }

    if (formField.errors && formField.errors.required) {
        return `<span class="text-red-500"> Required </span>`
    } else {
        if (name === 'date') {
            if (moment(formField.value).isValid() == true) {
                return `<span  class=""> ${moment(formField.value).format('MM/DD/YYYY')} </span>`
            } else {
                return `<span class="text-red-500"> Required </span>`
            }
        } else if (name === 'file') {

            if (formField.value instanceof File) {
                return `<a target="_blank" class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="${formField.value}" class=""> ${formField.value.name} </a>`
            } else if (formField.value instanceof ImageSnippet) {
                return `<a target="_blank" class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="${formField.value.file}" class=""> ${formField.value.file.name} </a>`
            } else {
                return `<a target="_blank" class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="${formField.value}" > ${getFileNameFromUrl(formField.value)} </a>`
            }
        } else {
            if (formField.value === '') {
                return `<span class="text-red-500"> Required </span>`
            } else {
                if (typeof formField.value === "boolean") {
                    if (formField.value)
                        return `<span  class=""> Yes </span>`
                    else
                        return `<span  class=""> No </span>`
                }

                if (formField.value === "true" || formField.value === "false") {
                    if (formField.value === "true")
                        return `<span  class=""> Yes </span>`
                    else
                        return `<span  class=""> No </span>`
                }

                return `<span  class=""> ${formField.value} </span>`
            }
        }
    }

    //  if (formField.errors && formField.errors.required) {
    //   return `<span class="text-red-500"> Required </span>`
    // } else {

    //   if (formField.value instanceof File) {
    //     return `<span  class=""> ${formField.value.name} </span>`
    //   } else if (moment(formField.value).isValid() == true) {
    //     return `<span  class=""> ${moment(formField.value).format('MM/DD/YYYY')} </span>`
    //   }

    //   else {
    //     return `<span  class=""> ${formField.value} </span>`
    //   }
    // }
}

export function imageValidation(formControlUrl, formControlFile) {
    if (formControlUrl.value === '') {
        console.log('File', formControlFile);
        return formControlFile;
    } else {
        console.log('url', formControlUrl);
        return formControlUrl;
    }
}

export const getDocumentList = {
    'hIPAATrainingCertificate': 'https://onboard-doc.s3.amazonaws.com/Celia+Roman-254638709.pdf',
    'disclosureOfOwnership': 'https://onboard-doc.s3.amazonaws.com/Disclosure+of+Ownership+Form.pdf',
    'eFTForm': 'https://onboard-doc.s3.amazonaws.com/EFT-Alivi+Direct+Deposit+Form.pdf',
    'eFTFormDoc': 'https://onboard-doc.s3.amazonaws.com/EFT-Alivi+Direct+Deposit+Form.docx',
    'fWACert': 'https://onboard-doc.s3.amazonaws.com/FWA+Cert.pdf',
    'voidedCheck': 'https://onboard-doc.s3.amazonaws.com/Voided+Check-+bank-letter.pdf',
    'w9': 'https://onboard-doc.s3.amazonaws.com/fw9.pdf'
};