import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  tokenizedReq;
  
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();
    const token = localStorage.getItem('token');
    
    if (req.url.indexOf("users/me") !== -1) {
      return next.handle(req);
    }
    
    if (req.body instanceof FormData) {
      this.tokenizedReq = req.clone({
         setHeaders: {
           Authorization: `JWT ${token}`,
         }
       });
    } 
    else {
      this.tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json'
        }
     });
    }

    return next.handle(this.tokenizedReq).pipe(
      tap(
        event => {
          status = '';
          if (event instanceof HttpResponse) {
            status = 'succeeded';
            event = event.clone({ body: + this.responselogDetails(JSON.stringify(event.body))});
          }
        },
        error => status = 'failed'
      ),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;
        const message =
         ' HttpRequest >>>>>>>>\n\n\n'
         + req.method + ' ' + req.urlWithParams + ' ' + status
          + ' in ' + elapsedTime + 'ms' + '\n body: ' + JSON.stringify(req.body) +
          '\n\n\n<<<<<<<< HttpRequest';
        this.logDetails(message);
      })
    );
  }

  private logDetails(msg: string) {
    console.log(msg);
  }

  private responselogDetails(msg: string) {
    console.log(' HttpResponse >>>>>>>>\n\n\n' +
    msg,
     '\n\n\n<<<<<<<< HttpResponse');
  }
}
