import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formValidations, getFileNameFromUrl } from 'src/app/utils/utils.common';
import { CompanyInformationService } from '../company-information-service/company-information.service';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  styleUrls: ['./company-view.component.css']
})
export class CompanyViewComponent implements OnInit {
  companyId;
  data;
  loading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyInformationService
            ) { }

  ngOnInit() {
    this.getCompanyInformation();
  }

  getCompanyInformation() {
    this.companyService.getCompanyDetails()
    .subscribe((data) => {
      console.log('Company Information ', data);
      this.data = data;
      this.loading = true;
    });
  }

  getFileName(url){
    return getFileNameFromUrl(url);
  }

  formValidations(formField, name?) {
    return formValidations(formField, name);
  }

}
