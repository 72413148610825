import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';
import { CompanyInformationService } from '../company-information/company-information-service/company-information.service';
import { DashboardService } from './dashboard-service/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user;
  driverCount;
  vehicleCount;
  showCompany = false;

  constructor(private router: Router, 
    private sharedData: SharedDataService,
    private dashboardService: DashboardService,
    private companyService: CompanyInformationService) { }

  ngOnInit() {
    this.sharedData.getUser().subscribe((data) => {
      if(data) {
        console.log('SHARED DATA', data);
        if (!this.user) {
          this.user = data;
          this.loadStats();
          this.getCompanyInformation();
        }
      }
    });
  }

  getCompanyInformation() {
    this.companyService.getCompanyDetails()
    .subscribe((data) => {
      console.log('Company Information ', data);
      if(data) {
        this.showCompany = true;
      }
    });
  }

  loadStats() {
    // console.log('LOADING STATS...');
    this.dashboardService.getStats().subscribe(data => {
      this.driverCount = data.driveralivi;
      this.vehicleCount = data.fleetalivi;
    });
  }

  openCompanyOverview() {
    this.router.navigate(['dashboard/company-view'])
  }

}
