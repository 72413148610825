import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { AppService } from "src/app/services/app-service/app.service";
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../auth-service/auth.service';
import { SharedDataService } from '../../shared-data/shared-data.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
    _id;
    auth_token = '';

    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService,
        private sharedData: SharedDataService
    ) {

    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        console.log('========= AUTH GUARD =========');
        
        // Getting token from URL & setting up token if not set.
        if (next.queryParams.auth_token) {
            this.auth_token = next.queryParams.auth_token;
            localStorage.setItem("token", this.auth_token);
        }
        else {
            this.auth_token = localStorage.getItem('token');
        }
        
        // Calling me everytime to authenticate the user.
        return this.auth.me(this.auth_token)
            .pipe(
                map((data: any) => {
                    if (data && data.user) {
                        this.sharedData.updateUser(data.user);
                        return true;
                    } else {
                        this.sharedData.updateUser(null);
                        localStorage.removeItem("token");
                        this.router.navigate(['notfound']);
                        return false;
                    }
                }),
                catchError(() => {
                    this.router.navigate(['notfound']);
                    return of(false);
                })
            );
    }
}

