import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../.././../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AppService {



  constructor(private http: HttpClient) { }

  getProviderDetails(id): Observable<any> {
    return this.http.get<any>(`${environment.URL}signuprequests/` + id);
  }

  addCompanyImages(id, images, companyImagesUrl): Observable<any> {
    const formData = new FormData();
    // formData.append('imageOne', images[0]);
    // formData.append('imageTwo', images[1]);
    // formData.append('imageThree', images[2]);
    // formData.append('imageFour', images[3]);
    // formData.append('imageOne', companyImagesUrl[0]);
    // formData.append('imageTwo', companyImagesUrl[1]);
    // formData.append('imageThree', companyImagesUrl[2]);
    // formData.append('imageFour', companyImagesUrl[3]);
    if (images[0] !== null) {
      formData.append('imageOne', images[0]);
    } else {
      if (companyImagesUrl[0] !== null) {
        formData.append('imageOne', companyImagesUrl[0]);
      } else {
        formData.append('imageOne', 'https://neighborhood-dev.s3.amazonaws.com/signuprequests-users/document_required.png');
      }
    }
    if (images[1] !== null) {
      formData.append('imageTwo', images[1]);
    } else {
      if (companyImagesUrl[1] !== null) {
        formData.append('imageTwo', companyImagesUrl[1]);
      } else {
        formData.append('imageTwo', 'https://neighborhood-dev.s3.amazonaws.com/signuprequests-users/document_required.png');
      }
    }
    if (images[2] !== null) {
      formData.append('imageThree', images[2]);
    } else {
      if (companyImagesUrl[2] !== null) {
        formData.append('imageThree', companyImagesUrl[2]);
      } else {
        formData.append('imageThree', 'https://neighborhood-dev.s3.amazonaws.com/signuprequests-users/document_required.png');
      }
    }
    if (images[3] !== null) {
      formData.append('imageFour', images[3]);
    } else {
      if (companyImagesUrl[3] !== null) {
        formData.append('imageFour', companyImagesUrl[3]);
      } else {
        formData.append('imageFour', 'https://neighborhood-dev.s3.amazonaws.com/signuprequests-users/document_required.png');
      }
    }
    return this.http.put<any>(`${environment.URL}signuprequests/` + id, formData);
  }
  generateOnboardingReport(data) {
    return this.http.post(`${environment.PDF_URL}report/onboarding`, { data });
  }

  updateOnboardData(id, reportData): Observable<any> {
    const formData = new FormData();
    formData.append('pdfFile', reportData.pdfFile);
    formData.append('step', 'finished');
    return this.http.put<any>(`${environment.URL}signuprequests/` + id, formData);
  }
}
