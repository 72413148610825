import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DriverService } from '../driver-service/driver.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {
  search;
  status;
  data = [];
  filteredList = [];

  constructor(
    private router: Router,
    private driverService: DriverService
  ) { }

  ngOnInit() {
    this.driverService.getAllDrivers().subscribe((data) => {
      console.log(data);
      if(data) {
        this.data = data;
        this.filteredList = data;
      }
    });
  }

  viewDriverDetails(id) {
    this.router.navigate(['/drivers/view',id])
  }

  editDriverDetails(id) {
    this.router.navigate(['/drivers/edit',id])
  }

  fillingStatus(driver) {
    let status = true;
    for (const key in driver) {
      // Skip the keys which do not start with 'is'
      // Skip the key 'isApproved'
      if (!key.startsWith('is') || key === 'isApproved') continue;

      // Decision based on false value of keys which starts with 'is'
      if (!driver[key]) {
        status = false;
        break;
      }
    }

    return status;
  }

  onStatusChange(status) {
    console.log(status);
    if (status) {
      this.data = this.filteredList.filter(driver => driver.status === status);
    }
    else {
      this.data = this.filteredList.filter(driver => driver.status === driver.status);
    }
  }

}
