import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { CompanyInformationService } from './company-information-service/company-information.service';
import { ToastrService } from 'ngx-toastr';
import { fileProcessing, formValidations, getAddress, getDocumentList, getFileNameFromUrl, imageValidation, logFormValues, onDateChange } from 'src/app/utils/utils.common';
import Swal from "sweetalert2";
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.css']
})
export class CompanyInformationComponent implements OnInit {
  documents = getDocumentList;
  companyForm1: FormGroup;
  companyForm2: FormGroup;
  companyForm3: FormGroup;
  companyForm4: FormGroup;
  companyForm5: FormGroup;
  companyForm6: FormGroup;
  companyForm7: FormGroup;

  submittedForm1 = false;
  submittedForm2 = false;

  showForm1 = true;
  showForm2 = false;
  showForm3 = false;
  showForm4 = false;
  showForm5 = false;
  showForm6 = false;
  showForm7 = false;

  step = {
    one: true,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false
  }

  companyId: string = ''
  isApproved: boolean = false;
  dtToday = moment().add(1,'days').startOf('day');
  user;
  companyExist;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sharedData: SharedDataService,
    private route: ActivatedRoute, private companyService: CompanyInformationService,
    private toastr: ToastrService) { }

  ngOnInit() {
    // this.route.queryParams.subscribe((params) => {
    //   // this._id = params.token;
    // });

    this.companyForm1 = this.fb.group({
      companyName: ['', Validators.required],
      city: [''],
      zip: [''],
      address_1: [''],
      address_1_lat: [0],
      address_1_lng: [0],
      // address_2: [''],
      // address_2_lat: [0],
      // address_2_lng: [0],
      officePhoneNumber: [''],
      secondaryPhoneNumber: [''],
      officeEmailAddress: [''],
      hoursofOperation: ['', Validators.required]
    });

    this.companyForm2 = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: [''],

      hIPAATrainingCertificateImage: [''],
      hIPAATrainingCertificateImageFile: [null],

      hIPAATrainingDate: [''],

      fraudWasteAndAbuseCertificateImage: [''],
      fraudWasteAndAbuseCertificateImageFile: [null],

      fraudWasteAndAbuseCertificateDate: [''],

      driverLicenseImage: [''],
      driverLicenseImageFile: [null],

      // PRE VALUES
      preHIPAATrainingDate: [''],
      preFraudWasteAndAbuseCertificateDate: ['']
    });

    this.companyForm3 = this.fb.group({
      nonDisclosureAgreementImage: [''],
      nonDisclosureAgreementImageFile: [null],

      transportationProviderAgreementImage: [''],
      transportationProviderAgreementImageFile: [null]
    });

    this.companyForm4 = this.fb.group({
      taxIDNumber: [''],

      w9Image: [''],
      w9ImageFile: [null],

      disclosureofOwnershipImage: [''],
      disclosureofOwnershipImageFile: [null],

      eFTFormImage: [''],
      eFTFormImageFile: [null],

      voidedCheckImage: [''],
      voidedCheckImageFile: [null]
    });

    this.companyForm5 = this.fb.group({
      generalLiabilityInsuranceImage: [''],
      generalLiabilityInsuranceImageFile: [null],

      generalLiabilityInsuranceDate: [''],

      driverScheduleImage: [''],
      driverScheduleImageFile: [null],


      autoLiabilityInsuranceImage: [''],
      autoLiabilityInsuranceImageFile: [null],


      autoLiabilityInsuranceDate: [''],

      vehicleScheduleImage: [''],
      vehicleScheduleImageFile: [null],

      // PRE VALUES 
      preGeneralLiabilityInsuranceDate: [''],
      preAutoLiabilityInsuranceDate: ['']
    });

    this.companyForm6 = this.fb.group({
      transportationCompanyName: [''],
      ambulatoryVehicles: [false],
      wheelchairVehicles: [false],
      stretcherVehicles: [false],
      vehicleForHireLicenseImage: [''],
      vehicleForHireLicenseImageFile: [null],
      provideService: ['']
    });

    this.companyForm7 = this.fb.group({
      bankName: [''],
      accountNumber: [''],
      routingNumber: ['']
    });

    this.loadData();

    this.sharedData.getUser().subscribe((data) => {
      if(data) {
        console.log('SHARED DATA', data);
        this.patchCompany(data);
        if(data.displayName) {
          this.companyExist = true;
        }
      }
    });
  }

  loadData() {
    this.companyService
      .getCompanyDetails()
      .subscribe(data => {
        if (data) {
          this.companyId = data._id;
          this.patchData(data);
          this.isApproved = data.isApproved;
        }
      });
  }

  saveData() {
    if(!this.allFormsValid()) {
      this.toastr.error('Invalid form', 'Error');
      return false;
    }
    
    const data = {
      ...this.companyForm1.value,
      ...this.companyForm2.value,
      ...this.companyForm3.value,
      ...this.companyForm4.value,
      ...this.companyForm5.value,
      ...this.companyForm6.value,
      ...this.companyForm7.value
    }

    this.companyService
      .saveCompanyInformation(this.companyId, data)
      .subscribe(data => {
        if (data) {
          this.patchData(data);
          this.companyId = data._id;
          this.toastr.success('Company Information saved successfully', 'Success');
          this.router.navigate(['dashboard']);
        } else {
          this.toastr.error('Error occured on server.', 'Error');
        }
      });

    console.log(data);
  }

  allFormsValid() {
    let isValid = true; 
    if (this.companyForm1.invalid || 
      this.companyForm2.invalid ||
      this.companyForm3.invalid ||
      this.companyForm4.invalid ||
      this.companyForm5.invalid ||
      this.companyForm6.invalid ||
      this.companyForm7.invalid) {
      isValid = false;
    }
    return isValid;
  }

  patchData(data) {
    this.companyForm1.patchValue(data);
    this.companyForm2.patchValue({
      ...data,
      preHIPAATrainingDate: data.hIPAATrainingDate,
      preFraudWasteAndAbuseCertificateDate: data.fraudWasteAndAbuseCertificateDate,
      hIPAATrainingCertificateImageFile: null,
      fraudWasteAndAbuseCertificateImageFile: null,
      driverLicenseImageFile: null,
    });
    this.companyForm3.patchValue({
      ...data,
      nonDisclosureAgreementImageFile: null,
      transportationProviderAgreementImageFile: null

    });
    this.companyForm4.patchValue({
      ...data,
      w9ImageFile: null,
      disclosureofOwnershipImageFile: null,
      eFTFormImageFile: null,
      voidedCheckImageFile: null

    });

    this.companyForm5.patchValue({
      ...data,
      preGeneralLiabilityInsuranceDate: data.generalLiabilityInsuranceDate,
      preAutoLiabilityInsuranceDate: data.autoLiabilityInsuranceDate,
      generalLiabilityInsuranceImageFile: null,
      driverScheduleImageFile: null,
      autoLiabilityInsuranceImageFile: null,
      vehicleScheduleImageFile: null
    });

    this.companyForm6.patchValue({
      ...data,
      vehicleForHireLicenseImageFile: null
    });
    this.companyForm7.patchValue(data);

  }

  patchCompany(data) {
    this.companyForm1.patchValue({
      companyName: data.displayName
    });
    this.companyForm6.patchValue({
      transportationCompanyName: data.displayName
    });
  }

  submitForm1() {
    this.submittedForm1 = true;
    logFormValues(this.companyForm1, '1');

    if (this.companyForm1.invalid) {
      return false;
    }

    this.step.two = true;
  }

  submitForm2() {
    this.submittedForm2 = true;
    logFormValues(this.companyForm2, '2');

    if (this.companyForm2.invalid) {
      return false;
    }

    this.step.three = true;
  }

  submitForm3() {
    logFormValues(this.companyForm3, '3');

    if (this.companyForm3.invalid) {
      return false;
    }

    this.step.four = true;
  }

  submitForm4() {
    logFormValues(this.companyForm4, '4');

    if (this.companyForm4.invalid) {
      return false;
    }

    this.step.five = true;
  }

  submitForm5() {
    logFormValues(this.companyForm5, '5');

    if (this.companyForm5.invalid) {
      return false;
    }

    this.step.six = true;
  }

  submitForm6() {
    logFormValues(this.companyForm6, '6');

    if (this.companyForm6.invalid) {
      return false;
    }

    this.step.seven = true;
  }

  submitForm7() {
    logFormValues(this.companyForm7, '7');

    if (this.companyForm7.invalid) {
      return false;
    }

    this.step.eight = true;
  }

  deleteFile(formControl, formKey) {
    if (this.isApproved) return false;
    Swal.fire({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#323484",
      cancelButtonColor: "#AAB7B8",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendDeleteRequest(formControl, formKey);        
      }
    });
  }

  sendDeleteRequest(formControl, formKey) {
    this.companyService.deleteDocument(this.companyId, formKey)
      .subscribe((resp) => {
        if (resp) {
          formControl.setValue('');
          Swal.fire(
            "Alert",
            "Document deleted successfully.",
            "success"
          );
        } else {
          Swal.fire(
            "Error",
            "Document not deleted successfully.",
            "error"
          );
        }
      });
  }

  getFileName(url) {
    return getFileNameFromUrl(url);
  }

  getAddress(place, form, address, latitude, longitude) {
    getAddress(place, form, address, latitude, longitude);
  }

  onDateChange(event, control) {
    onDateChange(event, control);
  }

  fileProcessing(ImageInput, form, imageFile) {
    fileProcessing(ImageInput, form, imageFile);
  }

  formValidations(formField, name?) {
    return formValidations(formField, name);
  }
  imageValidation(formControlUrl, formControlFile) {
    return imageValidation(formControlUrl, formControlFile);
  }

  get form1() {
    return this.companyForm1.controls;
  }

  get form2() {
    return this.companyForm2.controls;
  }

  get form3() {
    return this.companyForm3.controls;
  }

  get form4() {
    return this.companyForm4.controls;
  }

  get form5() {
    return this.companyForm5.controls;
  }

  get form6() {
    return this.companyForm6.controls;
  }

  get form7() {
    return this.companyForm7.controls;
  }

}
