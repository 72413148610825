import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/services/app-service/app.service';
import { DriverService } from '../driver-service/driver.service';
import * as moment from 'moment-timezone';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { ToastrService } from 'ngx-toastr';
import { fileProcessing, formValidations, getAddress, getFileNameFromUrl, imageValidation, onDateChange } from 'src/app/utils/utils.common';
import Swal from "sweetalert2";

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css']
})
export class AddDriverComponent implements OnInit {
  driverForm1: FormGroup;
  driverForm2: FormGroup;
  submittedForm1 = false;
  driverId;
  data;
  stepForm = 'one';
  screenLable = 'Add Driver';
  dtToday = new Date();

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit() {

    if (this.activatedRoute.snapshot.url.length === 3) {
      this.driverId = this.activatedRoute.snapshot.url[2].path;
      this.screenLable = 'Edit Driver';
      this.getDriverDetails();
    }

    this.driverForm1 = this.fb.group({

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      city: [''],

      address_1: [''],
      address_1_lat: [0],
      address_1_lng: [0],

      // address_2: [''],
      // address_2_lat: [0],
      // address_2_lng: [0],

      phoneNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      profileImageURL:[''],
      profileImageFile:[null],

    });

    this.driverForm2 = this.fb.group({

    
      driverLicenseImage: [''],
      driverLicenseImageFile: [null],

      driverLicenseNumber: [''],
      driverLicenseIssueDate: [''],
      driverLicenseExpireDate: [''],
      driverLicenseStateIssue: [''],
      dob: [''],
      languageSpoken: [''],

      latestPhyExamImage: [''],
      latestPhyExamImageFile: [null],

      latestPhyExamDate: [''],

      drugTestImage: [''],
      drugTestImageFile: [null],

      drugTestDate: [''],
      abuse: [false],
      violations: [false],
      countiesServed: [''],

      preDriverLicenseIssueDate: [''],
      preDriverLicenseExpireDate: [''],
      preDob: [''],
      preLatestPhyExamDate: [''],
      preDrugTestDate: [''],
      isApproved: [false]
    });

  }

  getDriverDetails() {
    this.driverService.getDriverDetails(this.driverId).subscribe((data) => {
      console.log(data);
      this.data = data;
      this.patchData(data);
    });
  }

  saveData() {
    if(!this.allFormsValid()) {
      this.toastr.error('Invalid form', 'Error');
      return false;
    }

    this.saveDriver();
    
    // this.driverService.verifyDriverEmail(this.driverForm1.value.email)
    //   .subscribe((data) => {
    //     console.log('Verify Driver Email ', data);
    //     //this.saveDriver();
    //   });
  }

  saveDriver() {
    console.log('Form2 ', this.driverForm1.value);
    const object = {
      ...this.driverForm1.value, ...this.driverForm2.value
    }
    console.log('SubmitForm Save', object)
    this.driverService.saveDriver(object, this.driverId).subscribe(data => {
      console.log('Response: ', data);
      if (data) {
        this.driverId = data._id;
        this.patchData(data);
        this.screenLable = 'Edit Driver';
        // this.toastr.success('Driver saved successfully', 'Success');
        this.router.navigate(['drivers']);
      } else {
        this.toastr.error('Error occured on server.', 'Error');
      }
    });
  }

  allFormsValid() {
    let isValid = true; 
    if (this.driverForm1.invalid || 
      this.driverForm2.invalid) {
      isValid = false;
    }
    return isValid;
  }

  patchData(data) {
    this.driverForm1.patchValue({
      ...data
    })

    this.driverForm2.patchValue({
      ...data,
      preDriverLicenseIssueDate: data.driverLicenseIssueDate,
      preDriverLicenseExpireDate: data.driverLicenseExpireDate ,
      preDob: data.dob,
      preLatestPhyExamDate: data.latestPhyExamDate,
      preDrugTestDate: data.drugTestDate,
      driverLicenseImageFile:null,
      latestPhyExamImageFile:null,
      drugTestImageFile:null
    })

    console.log('TST10: ', this.driverForm2.value);
  }

  submitForm1() {
    this.submittedForm1 = true;
    console.log('SubmitForm 1', this.driverForm1)
    if(this.driverForm1.invalid) {
      return false;
    }
    this.stepForm = 'two';
  }

  submitForm2() {
    this.stepForm = 'three';
    console.log('SubmitForm 2', this.driverForm2)
  }

  deleteFile(formControl, formKey) {
    Swal.fire({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#323484",
      cancelButtonColor: "#AAB7B8",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendDeleteRequest(formControl, formKey);        
      }
    });
  }

  sendDeleteRequest(formControl, formKey) {
    this.driverService.deleteDocument(this.driverId, formKey)
      .subscribe((resp) => {
        if (resp) {
          formControl.setValue('');
          Swal.fire(
            "Alert",
            "Document deleted successfully.",
            "success"
          );
        } else {
          Swal.fire(
            "Error",
            "Document not deleted successfully.",
            "error"
          );
        }
      });
  }

  getAddress(place, form, address, latitude, longitude) {
    getAddress(place, form, address, latitude, longitude);
  }

  onDateChange(event, control) {
    onDateChange(event, control);
  }

  fileProcessing(ImageInput, form, imageFile) {
    fileProcessing(ImageInput, form, imageFile);
  }

  getFileName(url){
    return getFileNameFromUrl(url);
  }

  formValidations(formField, name?) {
    return formValidations(formField, name);
  }

  imageValidation(formControlUrl, formControlFile) {
    return imageValidation(formControlUrl, formControlFile);
  }

  get form1() {
    return this.driverForm1.controls;
  }

  get form2() {
    return this.driverForm2.controls;
  }
}
