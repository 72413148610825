import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { VehicleService } from '../vehicle-service/vehicle.service';
// import { VehicleService } from '../../driver/driver-service/driver.service';
import { ToastrService } from 'ngx-toastr';
import { fileProcessing, formValidations, getAddress, getFileNameFromUrl, imageValidation, onDateChange } from 'src/app/utils/utils.common';
import Swal from "sweetalert2";

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.css']
})
export class AddVehicleComponent implements OnInit {

  vehicleForm1: FormGroup;
  vehicleForm2: FormGroup;
  submittedForm1 = false;
  // showform2 = false;
  stepForm = 'one';
  vehicleId;
  data;
  screenLable = 'Add Vehicle';

  constructor(
    private fb: FormBuilder,
    private vehicleService: VehicleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit() {

    if (this.activatedRoute.snapshot.url.length === 3) {
      this.vehicleId = this.activatedRoute.snapshot.url[2].path;
      this.screenLable = 'Edit Vehicle'
      this.getVehicleDetails();
    }

    this.vehicleForm1 = this.fb.group({

      description: ['', Validators.required],
      vin: ["0", Validators.required],
      licensePlateNumber: [''],
      year: [''],
      make: [''],
      model: [''],
      color: [''],
      vehicleType: ['', Validators.required],
      ambulatoryCapacity: [0],
      wheelchairCapacity: [0],
      stretcherCapacity: [0],

      garagedStreetAddress: [''],
      garagedStreetAddress_lat: [0],
      garagedStreetAddress_lng: [0],
      
      city: [''],
      state: [''],
      countiesServed: ['']
    });

    this.vehicleForm2 = this.fb.group({
      registrationImage: [''],
      registrationImageFile: [null],
      registrationDate: [''],
      inspectionReportImage: [''],
      inspectionReportImageFile: [null],
      inspectionReportDate: [''],
      vehiclePicture: [''],
      vehiclePictureFile: [null],

      preRegistrationDate: [''],
      preInspectionReportDate: [''],
      isApproved: [false]
    });

  }

  getVehicleDetails() {
    this.vehicleService.getVehicleDetails(this.vehicleId).subscribe((data) => {
      console.log(data);
      this.data = data;
      this.patchData(data);
    });
  }

  saveData() {
    if(!this.allFormsValid()) {
      this.toastr.error('Invalid form', 'Error');
      return false;
    }

    if (!this.vehicleForm1.value.vin) {
      this.vehicleForm1.patchValue({
        vin: "0"
      })
    }

    if (!this.vehicleForm1.value.ambulatoryCapacity) {
      this.vehicleForm1.patchValue({
        ambulatoryCapacity: 0
      })
    } 
    if (!this.vehicleForm1.value.wheelchairCapacity) {
      this.vehicleForm1.patchValue({
        wheelchairCapacity: 0
      })
    } 
    if (!this.vehicleForm1.value.stretcherCapacity) {
      this.vehicleForm1.patchValue({
        stretcherCapacity: 0
      })
    } 
    const object = {
      ...this.vehicleForm1.value, ...this.vehicleForm2.value
    }
    console.log('SubmitForm Save', object)
    this.vehicleService.saveVehicle(object, this.vehicleId).subscribe(data => {
      console.log('Response: ', data);
      if (data) {
        this.vehicleId = data._id;
        this.patchData(data);
        this.screenLable = 'Edit Vehicle'
        // this.toastr.success('Vehicle saved successfully', 'Success');
        this.router.navigate(['vehicles']);
      } else {
        this.toastr.error('Error occured on server.', 'Error');
      }
    });
  }

  allFormsValid() {
    let isValid = true; 
    if (this.vehicleForm1.invalid || 
      this.vehicleForm2.invalid) {
      isValid = false;
    }
    return isValid;
  }

  patchData(data) {
    this.vehicleForm1.patchValue({
      ...data
    })

    this.vehicleForm2.patchValue({
      ...data,
      preRegistrationDate: data.registrationDate,
      preInspectionReportDate: data.inspectionReportDate,
      registrationImageFile: null,
      inspectionReportImageFile: null,
      vehiclePictureFile: null
    })
  }

  submitForm1() {
    this.submittedForm1 = true;
    console.log('SubmitForm 1', this.vehicleForm1)
    if(this.vehicleForm1.invalid) {
      return false;
    }
    this.stepForm = 'two';
  }

  submitForm2() {
    this.stepForm = 'three';
    console.log('SubmitForm 2', this.vehicleForm2)
  }

  deleteFile(formControl, formKey) {
    Swal.fire({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#323484",
      cancelButtonColor: "#AAB7B8",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendDeleteRequest(formControl, formKey);        
      }
    });
  }

  sendDeleteRequest(formControl, formKey) {
    this.vehicleService.deleteDocument(this.vehicleId, formKey)
      .subscribe((resp) => {
        if (resp) {
          formControl.setValue('');
          Swal.fire(
            "Alert",
            "Document deleted successfully.",
            "success"
          );
        } else {
          Swal.fire(
            "Error",
            "Document not deleted successfully.",
            "error"
          );
        }
      });
  }

  getFileName(url){
    return getFileNameFromUrl(url);
  }

  getAddress(place, form, address, latitude, longitude) {
    getAddress(place, form, address, latitude, longitude);
  }

  onDateChange(event, control) {
    onDateChange(event, control);
  }

  fileProcessing(ImageInput, form, imageFile) {
    fileProcessing(ImageInput, form, imageFile);
  }

  formValidations(formField, name?) {
    return formValidations(formField, name);
  }

  imageValidation(formControlUrl, formControlFile) {
    return imageValidation(formControlUrl, formControlFile);
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
  
    if(!((keyCode > 95 && keyCode < 106) || (keyCode > 47 && keyCode < 58) || keyCode == 8)) {
        event.preventDefault();
    }
  }

  get form1() {
    return this.vehicleForm1.controls;
  }

  get form2() {
    return this.vehicleForm2.controls;
  }
}
