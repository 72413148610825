import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyInformationService {

  constructor(private http: HttpClient) { }

  saveCompanyInformation(companyId,data) : Observable<any> {
    const formData: FormData = new FormData();
    Object.keys(data).forEach(key => {
      if (data[key] instanceof Object) {
        formData.append(key, data[key].file);
      } else {
        formData.append(key, data[key]);
      }
    })

    if(companyId) {
      return this.http.put<any>(environment.URL + 'companyalivis/' + companyId, formData);
    } else {
      return this.http.post<any>(environment.URL + 'companyalivis' , formData);
    }
  }

  deleteDocument(companyId,formKey) : Observable<any> {
    const formData: FormData = new FormData();
    formData.append(formKey, '');
    return this.http.put<any>(environment.URL + 'companyalivis/' + companyId, formData);
  }

  editCompanyInformation(data) : Observable<any> {
    return this.http.post<any>(environment.URL + '', data)
  }

  getCompanyDetails(): Observable<any> {
    return this.http.get<any>(environment.URL + 'companyalivisdetail');
  }
}
